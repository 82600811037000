.color{
     width: 200px;
     height: 50px;
     color: white;
     border-radius: 5px;
     cursor: pointer;
     font-weight: bold;
     font-size: 20px;
     /* margin-left: 600px; */
     margin-top: 30px;
     
}
.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wrapper span{
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}